.lr-cover-layout {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: auto;
  grid-gap: 1em;
  cursor: pointer;
}

@media screen and (max-width: 1199px) {
  .lr-cover-layout {
    grid-template-columns: 1fr;
  }
}
