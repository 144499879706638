.lr-content-layout,
.lr-content-layout__inverted {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas: 'content-text content-image';
  grid-column-gap: 1em;
}

.lr-content-layout__inverted {
  grid-template-areas: 'content-image content-text';
}

@media screen and (max-width: 1199px) {
  .lr-content-layout,
  .lr-content-layout__inverted {
    grid-template-columns: auto;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      'content-image'
      'content-text';
    overflow: auto;
  }
}

.lr-content-layout-text {
  grid-area: content-text;
  height: 100%;
  box-sizing: border-box;
  overflow-y: auto;
}

@media screen and (max-width: 1199px) {
  .lr-content-layout-text {
    overflow-y: visible;
  }
}

.lr-content-layout-text h3,
.lr-content-layout-text p,
.lr-content-layout-text ul,
.lr-content-layout-text table {
  margin-bottom: calc(0.8rem * 1.6);
  color: var(--text);
}

.lr-content-layout-text h2 {
  margin-bottom: calc(1.6rem * 1.6);
  font-family: 'Playball', cursive;
  font-size: 2.4rem;
  line-height: 2.4rem;
  color: var(--text);
}

.lr-content-layout-text h3 {
  font-family: 'Playball', cursive;
  font-size: 1.6rem;
  line-height: 1.6rem;
}

.lr-content-layout-text a {
  text-decoration: none;
  color: var(--text);
}

.lr-content-layout-text p,
td {
  font-size: 0.8rem;
}

.lr-content-layout-text ul {
  padding-inline-start: 1rem;
}

.lr-content-layout-text li {
  font-size: 0.8rem;
}

.lr-content-layout-image {
  grid-area: content-image;
  overflow: hidden;
}

.lr-content-layout-image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}

@media screen and (max-width: 1199px) {
  .lr-content-layout-image {
    overflow: visible;
  }
}

ul.lr-content-layout-cv {
  display: grid;
  list-style-type: none;
  padding-left: 0;
  grid-gap: 1rem;
}

.lr-content-layout-cv li {
  display: grid;
  grid-template-columns: 5rem 1fr;
  grid-gap: 2rem;
}

.lr-content-layout-cv li span:first-child {
  text-align: right;
  font-style: italic;
}
