.lr-footer {
  height: 100%;
  display: grid;
  grid-template-columns: 3rem auto 1fr auto 3rem;
  grid-template-areas: '. footer-menu . footer-copyright .';
  align-items: center;
}

.lr-footer-menu {
  grid-area: footer-menu;
}

.lr-footer-copyright {
  grid-area: footer-copyright;
  font-size: 0.8rem;
}
