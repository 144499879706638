@import-normalize; /* Normalize styles */

:root {
  --text: rgba(36, 36, 36, 1);
}

html {
  box-sizing: border-box;
  font-family: 'Merriweather Sans', sans-serif;
  font-size: 12px;
  color: var(--text);
  line-height: calc(12px * 1.6);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

@media screen and (min-width: 320px) {
  html {
    font-size: calc(12px + 8 * ((100vw - 320px) / (1200 - 320)));
    line-height: calc((12px + 8 * ((100vw - 320px) / (1200 - 320))) * 1.6);
  }
}

@media screen and (min-width: 1200px) {
  html {
    font-size: 20px;
    line-height: calc(20px * 1.6);
  }
}

html,
body,
#root {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
