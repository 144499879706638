.lr-language-picker-list {
  display: flex;
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.lr-language-picker-item {
  margin-left: 5px;
  font-size: 0.6rem;
  text-transform: uppercase;
  list-style-type: none;
  cursor: pointer;
  color: var(--text);
}
