.lr-app-layout {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: 3rem 1fr 3rem;
  grid-template-areas:
    'header'
    'body'
    'footer';
}

.lr-app-layout-header,
.lr-app-layout-footer,
.lr-app-layout-body {
  width: 100%;
  overflow: hidden;
}

.lr-app-layout-header {
  grid-area: header;
}

.lr-app-layout-footer {
  grid-area: footer;
}

.lr-app-layout-body {
  grid-area: body;
}

@media screen and (max-width: 1199px) {
  .lr-app-layout-body {
    height: auto;
    overflow: hidden;
  }
}
