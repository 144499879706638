.lr-header {
  height: 100%;
  display: grid;
  grid-template-columns: 3rem auto 1fr auto 3rem;
  grid-template-areas: '. header-menu . header-languages .';
  align-items: center;
}

.lr-header-menu {
  grid-area: header-menu;
}

.lr-header-languages {
  grid-area: header-languages;
}
