.lr-body-layout {
  height: 100%;
  display: flex;
}

.lr-body-center {
  width: calc(100% - (2 * 3rem));
  height: 100%;
  overflow: hidden;
}
