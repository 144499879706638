.lr-nav-list-title {
  margin-right: 30px;
  font-family: 'Playball', cursive;
  font-size: 1.6rem;
  cursor: pointer;
  white-space: nowrap;
}

.lr-nav-list > ul {
  display: flex;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.lr-logo {
  height: 1.5rem;
  pointer-events: none;
}
