.lr-cover-layout-tile {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.lr-cover-layout-tile h2 {
  font-family: 'Playball', cursive;
  font-size: 4em;
  color: var(--text);
}

.lr-cover-layout-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
